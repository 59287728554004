import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import Container from '~components/Container';
import { generateUrlFriendlyIdFromTitle } from '~utils/url-helper';
import ContentSection from './ContentSection';

const useStyles = makeStyles({
  root: {
    backgroundColor: props => props.background_color,
    position: 'relative',
    paddingTop: '15px',
    paddingBottom: '15px'
  }
});

const ArticleContentBox = ({
  text_block_title_rich_text,
  text_block_content_rich_text,
  background_color,
  containerSize
}) => {
  const location = useLocation();
  const classes = useStyles({ background_color });
  const sectionId = useMemo(
    () => generateUrlFriendlyIdFromTitle(text_block_title_rich_text?.text),
    [text_block_title_rich_text]
  );

  useEffect(() => {
    // expand when url hash has a section id
    if (location?.hash && sectionId === location.hash.substring(1)) {
      navigate(location?.hash);
    }
  }, [location?.hash, sectionId]);

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth={containerSize}>
          <ContentSection
            id={sectionId}
            title={text_block_title_rich_text?.html}
            content={text_block_content_rich_text?.html}
          />
        </Container>
      </div>
    </>
  );
};

ArticleContentBox.propTypes = {
  background_color: PropTypes.string,
  text_block_title_rich_text: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }),
  text_block_content_rich_text: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  containerSize: PropTypes.string
};

ArticleContentBox.defaultProps = {
  text_block_title_rich_text: null,
  background_color: '#FFFFFF',
  containerSize: 'md'
};

export default ArticleContentBox;
